import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { Template, TemplateAdmin, Store } from "components";

import * as pages from "./pages";

const RoutesAdmin = () => (
  <TemplateAdmin>
    <Route path="/admin" component={pages.Dashboard} exact />

    <Route path="/admin/users" component={pages.Users} exact />

    <Route path="/admin/companies" component={pages.Companies} exact />

    <Route
      path="/admin/company/:company/category"
      component={pages.Categories}
      exact
    />

    <Route
      path="/admin/company/:company/article"
      component={pages.ArticleAdmin}
      exact
    />

    <Route
      path="/admin/company/:company/comments"
      component={pages.Comments}
      exact
    />

    <Route
      path="/admin/company/:company/article/new"
      component={pages.NewArticle}
      exact
    />

    <Route
      path="/admin/company/:company/article/:article/edit"
      component={pages.NewArticle}
      exact
    />

    <Route
      path="/admin/company/:company/select-home-categories"
      component={pages.SelectHomeCategories}
      exact
    />

    <Route
      path="/admin/company/:company/recomended-articles"
      component={pages.RecomendedArticles}
      exact
    />
  </TemplateAdmin>
);

const RoutesSite = () => (
  <Template>
    <Route path="/:company" component={pages.Validate} />
    <Route path="/" component={pages.Company} exact>
      <Redirect to="/075922202003145e6c8eda6c455" />
    </Route>
    <Route path="/:company" component={pages.Company} exact />
    <Route path="/:company/c" component={pages.Category} exact />
    <Route path="/:company/disabled" component={pages.Disabled} exact />
    <Route path="/:company/c/:category" component={pages.Category} exact />
    <Route
      path="/:company/c/:category/a/:article"
      component={pages.Article}
      exact
    />
  </Template>
);

export const Routes = () => {
  return (
    <Store.Provider>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={pages.Login} exact />
          <Route path="/admin" component={RoutesAdmin} />
          <Route path="/" component={RoutesSite} />
        </Switch>
      </BrowserRouter>
    </Store.Provider>
  );
};
