import React from "react";
import { Button, Table } from "react-bootstrap";
import styled from "styled-components";
import * as R from "ramda";

import { PageTitle } from "components";

import { useLoading } from "hooks";
import api from "api";
import { formatDate } from "utils/date";

const Th = styled.th`
  font-weight: 400;
`;

const Space = styled.div`
  margin-top: 30px;
`;

export const RecomendedArticles = ({ location, match }) => {
  const { data, isLoading, fetch } = useLoading(
    () => api.get("/article?company_id=" + match.params.company),
    false,
    [location.pathname]
  );

  const hasTen =
    R.pathOr([], ["success", "articles"], data).filter(
      (x) => x.is_recommended === "recommended" && x.visible === "public"
    ).length >= 10;

  const handleOnEdit = async (value, idArticle) => {
    if (hasTen && value === "recommended") {
      alert("Você ja tem 10 Artigos Selecionados");
    } else {
      await api.post("/article/setRecomended/" + idArticle + "?value=" + value);
      fetch();
    }
  };
  return (
    <>
      <PageTitle>Selecionador de Artigos Recomendados</PageTitle>
      <hr />
      <Space />
      {isLoading || !data ? (
        "Carregando"
      ) : (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <Th>Adicionado</Th>
              <Th>Artigo</Th>
              <Th>Data Criação</Th>
              <Th>Views</Th>
              <Th>Ação</Th>
            </tr>
          </thead>
          <tbody>
            {data.success.articles
              .filter((item) => item.visible === "public")
              .map((article) => (
                <tr>
                  <td>
                    {article.is_recommended === "recommended" ? "Sim" : "Não"}
                  </td>
                  <td>{article.name}</td>
                  <td>{formatDate(article.date)}</td>
                  <td>{article.views}</td>
                  <td>
                    {article.is_recommended === "recommended" ? (
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() =>
                          handleOnEdit("not_recommended", article.id)
                        }
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        variant="outline-success"
                        size="sm"
                        disabled={hasTen}
                        onClick={() => handleOnEdit("recommended", article.id)}
                      >
                        Adiciona
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
