import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import styled from "styled-components";

import { useLoading } from "hooks";
import api from "api";

const Span = styled.button`
  margin-right: 16px;
  padding: 4px 8px;
  font-size: 14px;
`;

export const Reactions = ({ articleId }) => {
  const { fetch } = useLoading(
    (params) => api.post(`/site/article/${articleId}/addReaction`, params),
    true
  );

  const handleOnClick = (type) => () => {
    fetch({ reaction_type: type });
    alert("Obrigado por feedback");
    window.location.reload();
  };

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <h3>Esta informação foi útil?</h3>
            <hr />
            <Span onClick={handleOnClick("like")}>
              <FaRegThumbsUp /> Sim muito útil
            </Span>
            <Span onClick={handleOnClick("unlike")}>
              <FaRegThumbsDown /> Não foi útil
            </Span>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
