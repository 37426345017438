import React from "react";
import { withRouter } from "react-router-dom";
import { useLoading } from "hooks";
import api from "api";

import { ItemCategory } from "./ItemCategory";

export const ListCategoriesTypeComponent = ({
    history,
    match,
    hashCompany,
    title,
    isCompany,
}) => {
    const { data, isLoading } = useLoading(() =>
        api.get("/site/category?company_hash=" + hashCompany)
    );

    React.useEffect(() => {
        if (!match.params.category && isCompany && data) {
            const firstCategory = data.success.categories[0];

            firstCategory &&
                history.push({
                    pathname: `/${match.params.company}/c/${firstCategory.id}`,
                });
        }
    }, [data]);

    const formatedData = React.useMemo(() => {
        if (!data) return [];

        const getLevel = (categoryId, level) => {
            return data.success.categories
                .filter(
                    (item) => item.category_id === categoryId && item.level === level
                )
                .reduce((acc, item) => {
                    return [...acc, item, ...getLevel(item.id, item.level + 1)];
                }, []);
        };

        return data.success.categories
            .filter((item) => item.level === 1)
            .map((item) => {
                return {...item, sub_categories: getLevel(item.id, item.level + 1) };
            });
    }, [data]);

    if (isLoading || !data) return "carregando";

    return ( <
        >
        {
            /* <h3>{title}</h3>
                  <hr /> */
        } {
            formatedData.map((category, i) => ( <
                ItemCategory key = { i }
                category = { category }
                />
            ))
        } <
        />
    );
};

export const ListCategoriesType = withRouter(ListCategoriesTypeComponent);