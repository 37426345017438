import React from "react";
import { Card as RBCard, Button } from "react-bootstrap";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { routeGoBack } from "utils/routes";

import { ListCategoriesType } from "./ListCategoriesType";

const BTToBack = styled(Button)`
  border-radius: 0;
  margin-top: 20px;
  padding: 8px 20px;
  width: 100%;
`;

const Card = styled(RBCard)`
  border-radius: 0;
  margin: 12px 0;
`;

const ListCategoriesComponent = ({ match, history }) => {
  const hashCompany = match.params.company;
  const hashCommon = "075922202003145e6c8eda6c455";

  const handleOnBack = () => {
    history.push({
      pathname: routeGoBack(2),
    });
  };

  return (
    <>
      <BTToBack variant="success" onClick={handleOnBack}>
        {`<< Voltar`}
      </BTToBack>

      <Card>
        <Card.Body>
          <h3>Categorias</h3>
          <hr />
          <ListCategoriesType
            hashCompany={hashCompany}
            title="Categorias"
            isCompany
          />

          {hashCommon !== hashCompany && (
            <>
              {/* <hr /> */}

              <ListCategoriesType
                hashCompany={hashCommon}
                title="Categorias Gerais"
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export const ListCategories = withRouter(ListCategoriesComponent);
