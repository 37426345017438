import React from "react";
import { Card as RBCard } from "react-bootstrap";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { useLoading } from "hooks/useLoading";
import api from "api";

const Card = styled(RBCard)`
  border-radius: 0;
`;

const Item = styled.a`
  display: block;
  padding: 8px 0;
  color: #333;

  &:hover {
    display: block;
    padding: 8px 0;
    color: #333;
  }
`;

const RelactedArticlesComponent = ({ category, hash }) => {
  const { data, isLoading } = useLoading(
    () => api.get("/site/article?category_id=" + category),
    false
  );

  if (!data || isLoading) return "carregando";

  return (
    <Card>
      <Card.Body>
        Artigos Relacionadas
        <hr />
        {data.success.articles.map((article, i) => (
          <Item key={i} href={`/${hash}/c/${category}/a/${article.url}`}>
            {article.name}
          </Item>
        ))}
      </Card.Body>
    </Card>
  );
};

export const RelactedArticles = withRouter(RelactedArticlesComponent);
