import React from "react";
import { withRouter } from "react-router-dom";
import { Store } from "components";
import { path } from "ramda";

import * as S from "./sidebarItem.styled";

const SidebarItemComponent = ({ history, data }) => {
  const { store } = React.useContext(Store.Context);
  const [open, setOpen] = React.useState(false);

  const goTo = (pathname) => () => {
    history.push({
      pathname,
    });
  };

  if (
    path(["user", "type"], store) === "ADMIN" ||
    (path(["user", "type"], store) === "USER" &&
      JSON.parse(path(["user", "company"], store)).indexOf(data.id) > -1)
  ) {
    return (
      <S.Container>
        <S.Link href={`${window.location.origin}/${data.hash}`} target="_blank">
          <S.IconChevRg />
          Ir P/ {data.name}
        </S.Link>

        <S.Title onClick={() => setOpen(!open)}>
          <S.IconChevDw />
          {data.name}
        </S.Title>

        <S.SubItems open={open}>
          <S.SubItem onClick={goTo(`/admin/company/${data.id}/article`)}>
            Gerenciar Artigos
          </S.SubItem>

          {path(["user", "type"], store) === "ADMIN" && (
            <>
              <S.SubItem onClick={goTo(`/admin/company/${data.id}/comments`)}>
                Gerenciar Comentarios
              </S.SubItem>

              <S.SubItem onClick={goTo(`/admin/company/${data.id}/category`)}>
                Gerenciar Categorias
              </S.SubItem>

              <S.SubItem
                onClick={goTo(`/admin/company/${data.id}/recomended-articles`)}
              >
                10+ Recomendados
              </S.SubItem>

              <S.SubItem
                onClick={goTo(
                  `/admin/company/${data.id}/select-home-categories`
                )}
              >
                Categorias da Home
              </S.SubItem>
            </>
          )}
        </S.SubItems>
      </S.Container>
    );
  }
  
  return false;
};

export const SidebarItem = withRouter(SidebarItemComponent);
