import React from "react";
import { Button, Form } from "react-bootstrap";

import { useForm } from "hooks/useForm";
import { useLoading } from "hooks/useLoading";
import api from "api";

const validations = {
  name: [[data => !data, "O campo não pode ser vazio"]]
};

export const FormUpdateCompany = ({ idCompany, onClose }) => {
  const [loader, setLoader] = React.useState(false);
  const { isLoading, fetch } = useLoading(
    () => api.get("/company/" + idCompany),
    true
  );

  const [form, onChange] = useForm({ validations });

  React.useEffect(() => {
    if (idCompany)
      fetch().then(data => {
        form.setValues({
          ...data.success.company
        });
      });
  }, []);

  const handleOnUpdate = async e => {
    e.preventDefault();

    setLoader(true);

    const params = {
      name: form.getValue("name")
    };

    const { data } = await api
      .post("/company/edit/" + idCompany, params)
      .catch(error => error.response);

    if (data.errors) return form.setError(data.errors);

    form.clear({ name: "" });

    setLoader(false);

    onClose();
  };

  return (
    <Form>
      {!isLoading && (
        <>
          <Form.Group>
            <Form.Label>Empresa</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome Empresa"
              value={form.getValue("name")}
              onChange={e => onChange("name")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("name")}
            </Form.Text>
          </Form.Group>
          <Button
            variant="outline-success"
            disabled={form.hasErrors}
            onClick={handleOnUpdate}
          >
            {loader ? "Atualizando..." : "Atualizar"}
          </Button>
        </>
      )}
    </Form>
  );
};
