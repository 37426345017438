import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import { PageTitle } from "components";
import { FormUser } from "./components/FormUser";

import { useLoading } from "hooks/useLoading";
import api from "api";

const Space = styled.span`
  display: inline-block;
  margin-top: 8px;
`;

const SpaceLeft = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

const Th = styled.th`
  font-weight: 400;
`;

export const Users = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { data, isLoading, fetch } = useLoading(() => api.get("/users"));

  const HandleOnStatus = async (status, id) => {
    const {} = await api.post("/user/updateStatus/" + id + "?status=" + status);
    fetch();
  };

  const handleOnDelete = async (id) => {
    await api.post("/user/delete/" + id);
    fetch();
  };

  const { data: dataCategories, isLoading: isLoadingCompany } = useLoading(() =>
    api.get("/category")
  );


  const getCategory = (id) => {
    if (!isLoadingCompany && dataCategories) {
      const category = dataCategories.success.categories.filter((item) => item.id == id);

      if (category.length) {
        return category[0].name;
      }
    }
  };

  return (
    <>
      <PageTitle>Gerenciador de Usuários</PageTitle>
      <Button
        variant="outline-success"
        size="sm"
        onClick={() => setShowModal({ type: "form" })}
      >
        Criar Usuario
      </Button>
      <hr />
      <Space />
      {!data || isLoading ? (
        "Carregando"
      ) : (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <Th>Nome</Th>
              <Th>User</Th>
              <Th>Tipo</Th>
              <Th>Status</Th>
              <Th>Ações</Th>
            </tr>
          </thead>
          <tbody>
            {data.success.users.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.user}</td>
                <td>{isNaN(user.type) ? user.type : getCategory(user.type)}</td>
                <td>{user.status === "active" ? "Ativo" : "Inativo"}</td>
                <td>
                  {user.status === "active" ? (
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => HandleOnStatus("deactivated", user.id)}
                    >
                      Bloquear
                    </Button>
                  ) : (
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() => HandleOnStatus("active", user.id)}
                    >
                      Ativar
                    </Button>
                  )}
                  <SpaceLeft />

                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() =>
                      setShowModal({ type: "form", user: user.id })
                    } //
                  >
                    Editar
                  </Button>
                  <SpaceLeft />

                  <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={() =>
                      setShowModal({ type: "delete", user: user.id })
                    }
                  >
                    Excluir
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        show={showModal.type === "form"}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de Usuario </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormUser
            idUser={showModal.user}
            closeModal={() => {
              setShowModal(false);
              fetch();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => setShowModal(false)}>
            Cancela
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal.type === "delete"}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Exclusão de Usuario </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que seja excluir este usuario permanentemente?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-success"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Não
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              handleOnDelete(showModal.user);
              setShowModal(false);
            }}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
