import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const TabCheck = styled.div`
  &&.padding1 {
    padding-left: 0;
  }
  &&.padding2 {
    padding-left: 16px;
  }
  &&.padding3 {
    padding-left: 32px;
  }
  &&.padding4 {
    padding-left: 48px;
  }
`;

export const ListCategories = ({ categories, value = [], onChange, error }) => {
  const handleOnSelect = (e) => {
    const selected = parseInt(e.target.value, 10);

    if (value.includes(selected)) {
      return onChange(value.filter((item) => item !== selected));
    }

    onChange([...value, selected]);
  };

  return (
    <Form>
      <Form.Group id="formGridCheckbox">
        {" "}
        {categories.map((category, i) => (
          <TabCheck
            key={i}
            className={
              (category.level === 1 && "padding1") ||
              (category.level === 2 && "padding2") ||
              (category.level === 3 && "padding3") ||
              (category.level === 4 && "padding4")
            }
          >
            <Form.Check
              key={category.id}
              type="checkbox"
              label={
                (category.level ? "".repeat(category.level - 1) + " " : "") +
                category.name
              }
              value={category.id}
              checked={value.includes(category.id)}
              onChange={handleOnSelect}
            />{" "}
          </TabCheck>
        ))}{" "}
      </Form.Group>{" "}
      <Form.Text className="text-danger"> {error} </Form.Text>{" "}
    </Form>
  );
};
