import React from "react";
import * as R from "ramda";

export const useLoading = (
  getData = () => {},
  lazy = false,
  update = [],
  initialLoading = false
) => {
  const [isLoading, setLoading] = React.useState(initialLoading);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    lazy || fetchData();
  }, update);

  const fetchData = async (...props) => {
    setLoading(true);

    const response = await getData(...props).catch(err => err);

    setLoading(false);

    const data = R.path(["data"], response);

    setData(data);

    return data;
  };

  return { data: data, fetch: fetchData, isLoading };
};
