import React from "react";
import { Row, Col, Table, Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import { useLoading } from "hooks";
import api from "api";

import { PageTitle } from "components";
import { formatDate } from "utils/date";

const Th = styled.th`
  font-weight: 400;
`;

const Spaco = styled.div`
  margin-top: 24px;
`;

const Spaceleft = styled.span`
  margin-right: 8px;
  display: inline-block;
`;

const Ver = styled.span`
  color: red;
`;

export const Comments = ({ location, match }) => {
  const [showModal, setShowModal] = React.useState({ show: false });
  const [publics, setPublics] = React.useState(false);

  const { data, isLoading, fetch } = useLoading(
    () => api.get("comments?company_id=" + match.params.company),
    false,
    [location.pathname]
  );

  const handleUpDate = async (value, id) => {
    await api.post("comment/setAproved/" + id + "?set=" + value);
    fetch();
  };

  const handleDelete = async (CommentID) => {
    await api.post("/comment/delete/" + CommentID);
    fetch();
  };

  if (!data || isLoading) return "Carregando";

  const comments = data.success.comments;

  return (
    <>
      <Row>
        <Col>
          <PageTitle>Administrador de Comentários</PageTitle>
        </Col>
      </Row>
      <hr />
      <Spaco />
      <Button
        size="sm"
        variant="outline-dark"
        onClick={() => setPublics(!publics)}
      >
        {publics ? "Ver Pendentes" : "Ver Publicados"}
      </Button>
      <Spaco />

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <Th>Nome</Th>
            <Th>Status</Th>
            <Th>Data</Th>
            <Th>Artigo</Th>
            <Th>Ação</Th>
          </tr>
        </thead>
        <tbody>
          {comments
            .filter(
              publics
                ? (item) => item.status === "approved"
                : (item) => item.status === "pendding"
            )
            .map((comment, key) => (
              <tr>
                <td>{comment.name}</td>
                <td>
                  {comment.status === "approved" ? (
                    <> Aprovado</>
                  ) : (
                    <Ver>Pendente</Ver>
                  )}
                </td>
                <td>{formatDate(comment.date)}</td>
                <td>{comment.article_name}</td>
                <td>
                  <Button
                    size="sm"
                    variant="outline-info"
                    onClick={() => setShowModal({ show: true, comment: key })}
                  >
                    Conteudo
                  </Button>
                  {comment.status === "pendding" ? (
                    <>
                      <Spaceleft />
                      <Button
                        size="sm"
                        variant="outline-success"
                        onClick={() => handleUpDate("approved", comment.id)}
                      >
                        Aprovar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Spaceleft />
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => handleUpDate("pendding", comment.id)}
                      >
                        Rever
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showModal.show && (
        <Modal
          show={showModal.show}
          onHide={() => setShowModal({ show: false })}
        >
          <Modal.Header>
            {comments[showModal.comment].article_name}
          </Modal.Header>
          <Modal.Body>
            <i>{comments[showModal.comment].name + " "}diz:</i>
            <p>"{comments[showModal.comment].content}".</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => {
                handleDelete(comments[showModal.comment].id);
                setShowModal({ show: false });
              }}
            >
              Excluir
            </Button>
            <Button
              size="sm"
              variant="outline-info"
              onClick={() => setShowModal({ show: false })}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
