import React from "react";
import { Row, Card as RBCard, Col, Button as RBButton } from "react-bootstrap";
import styled from "styled-components";

import { useLoading } from "hooks";
import { Body } from "components";
import BgConteudo from "img/bg_conteudo.png";
import api from "api";

import { Bloco } from "./components/Bloco";

const CardBody = styled(RBCard.Body)`
  text-align: center;
  padding-bottom: 36px;
`;

const TitleCB = styled.h2`
  margin-bottom: 20px;
  margin: auto;
  margin-top: -24px;
  background: #ffffff;
  padding: 5px 20px;
  text-align: center;
`;

const Card = styled(RBCard)`
  border-radius: 0;
  margin: 24px 0 44px;
  min-height: 480px;
`;

const Item = styled.a`
  display: block;
  padding: 8px 0;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #333;
    text-decoration: underline;
  }
`;

const Button = styled(RBButton)`
  padding: 12px 30px;
  background-color: #6fba3b;
  border: 0;
  border-radius: 0;
  margin: 20px auto 40px;
  display: block;

  &:hover,
  :active,
  :focus {
    padding: 12px 30px;
    background-color: #6fba3b !important;
    border: 0;
    border-radius: 0;
    margin: 20px auto 40px;
    display: block;
    box-shadow: none !important;
    border-color: #6fba3b !important;
  }
`;

const Container = styled.div`
  background-image: url(${BgConteudo});
  background-position: center;
  background-size: cover;
  padding-bottom: 60px;
  padding-top: 30px;
  margin-bottom: -20px;
`;
const LineTitle = styled.span`
  background: #fff;
  padding: 20px 40px;
  margin: 0 auto -40px;
  display: table;
  font-size: 30px;
`;
const Line = styled.span`
  border-bottom: 1px solid #ddd;
  display: block;
  margin-bottom: 80px;
`;

export const Company = ({ history, location, match }) => {
  const { data, isLoading } = useLoading(() =>
    api.get("/site/category/home?company_hash=" + match.params.company)
  );

  const { data: data2, isLoading: isLoading2 } = useLoading(() =>
    api.get("/site/company/" + match.params.company)
  );

  const { data: dataDez, isLoading: isLoadingDez } = useLoading(() =>
    api.get("site/articles/dezmais/" + match.params.company)
  );

  const { data: dataMLidos, isLoading: isLoadingMLidos } = useLoading(() =>
    api.get("site/articles/maislidos/" + match.params.company)
  );

  if (isLoading || !data) return <Body>Carregando...</Body>; // deu erro quando, colocado na tabela
  if (!data2 || isLoading2) return "";

  const handleOnClick = (value) => {
    history.push({
      pathname: "/" + match.params.company + "/c/" + value,
    });
  };

  const goTo = (pathname) => () => {
    history.push({
      pathname,
    });
  };

  return (
    <Container>
      {data2.success.company.status === "active" ? (
        <Body>
          {/* <PageTitle>CONSULTE PROCEDIMENTOS</PageTitle> */}
          <Row>
            {data.success.categories.map((category, i) => (
              <Bloco
                key={i}
                onClick={() => handleOnClick(category.id)}
                Title={category.name}
                Description={category.description}
                QTArtigos={category.articles}
              />
            ))}
          </Row>

          <Button onClick={goTo(`/${match.params.company}/c/`)}>
            Ver Todos
          </Button>

          <Line>
            <LineTitle>Escolhidos a Dedo</LineTitle>
          </Line>
          <Row>
            <Col md={6} xs={12}>
              <Card>
                <TitleCB>Principais artigos</TitleCB>
                {!dataDez || isLoadingDez ? (
                  "carregando"
                ) : (
                  <CardBody>
                    {dataDez.success.articles.map((article, key) => (
                      <Item
                        key={key}
                        href={`/${match.params.company}/c/${article.category_id}/a/${article.url}`}
                      >
                        {article.name} <br />
                      </Item>
                    ))}
                  </CardBody>
                )}
              </Card>
            </Col>

            <Col md={6} xs={12}>
              <Card>
                <TitleCB>Artigos Mais Lidos</TitleCB>
                {!dataMLidos || isLoadingMLidos ? (
                  "carregando"
                ) : (
                  <CardBody>
                    {dataMLidos.success.articles.map((article, key) => (
                      <Item
                        key={key}
                        href={`/${match.params.company}/c/${article.category_id}/a/${article.url}`}
                      >
                        {article.name} <br />
                      </Item>
                    ))}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Body>
      ) : (
        <Body>Este serviço está desativado para sua empresa neste momento</Body>
      )}
    </Container>
  );
};
