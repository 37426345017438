import React from "react";
import { withRouter } from "react-router-dom";

import { Search } from "../search/Search";

const HeaderComponent = () => {
  return <Search />;
};

export const Header = withRouter(HeaderComponent);
