import axios from "axios";

//export const baseURL = "https://api.juliobessa.com.br"; // dev
export const baseURL = "https://api.suporti.com/"; // produção
//export const baseURL = "http://localhost:8000"; // dev

let token = "";

export const setToken = (data) => {
    token = data;
};

const instance = axios.create({
    baseURL,
    timeout: 10000,
});

instance.interceptors.response.use(
    (data) => data,
    (error) => {
        if (error.response.status === 401) return (window.location = "/login");
        return error;
    }
);

instance.interceptors.request.use(
    (config) => {
        return {
            ...config,
            data: {
                ...(config.data || {}),
                auth: token,
            },
            params: {
                ...(config.params || {}),
                auth: token,
            },
        };
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;