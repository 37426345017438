export const formatDate = date => {
  let data = new Date(date)
  data.setHours( data.getHours() - 3 );
  data = data.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/Sao_Paulo'
  });
  return data;
}
