import React from "react";
import { Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { useForm } from "hooks";
import api from "api";

const validations = {
  name: [[data => !data, "O campo não pode ser vazio"]]
};

const FormCategoriesComponent = ({ fetchCategory, categories, match, fetchCompany }) => {
  const [form, onChange] = useForm({ validations });

  const handleOnSave = async e => {
    e.preventDefault();

    const params = {
      company_id: match.params.company,
      category_id: form.getValue("category_id"),
      name: form.getValue("name")
    };

    const { data } = await api
      .post("category/new", params)
      .catch(error => error.response);

    if (data.errors) return form.setError(data.errors);

    onChange("name")("");
    fetchCategory();
    fetchCompany();
  };

  return (
    <Form>
      <Form.Group>
        <Form.Label>Nome Categoria</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nome Categoria"
          value={form.getValue("name")}
          onChange={e => onChange("name")(e.target.value)}
        />
      </Form.Group>
      <Form.Text className="text-danger">{form.getError("name")}</Form.Text>

      <Form.Group>
        <Form.Label>Selecione a categoria Pai</Form.Label>
        <Form.Control
          as="select"
          value={form.getValue("category_id")}
          onChange={e => onChange("category_id")(parseInt(e.target.value, 10))}
        >
          <option key="null" value="">
            Categoria Pai
          </option>

          {categories.map(category => (
            <option key={category.id} value={category.id}>
              {category.level
                ? `\u00A0\u00A0\u00A0`.repeat(category.level - 1)
                : ""}
              {category.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button
        variant="outline-success"
        disabled={form.hasErrors}
        onClick={handleOnSave}
      >
        Salvar
      </Button>
    </Form>
  );
};

export const FormCategories = withRouter(FormCategoriesComponent);
