import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { PageTitle } from "components";
import { useForm } from "hooks";
import api from "api";

const validations = {
  name: [[data => !data, "O campo não pode ser vazio"]],
  email: [[data => !data, "O campo não pode ser vazio"]],
  content: [[data => !data, "O campo não pode ser vazio"]]
};

export const FormComment = ({ postId }) => {
  const [form, onChange] = useForm({ validations });

  const handleOnSave = async e => {
    e.preventDefault();

    const params = {
      article_id: parseInt(postId),
      name: form.getValue("name"),
      email: form.getValue("email"),
      content: form.getValue("content")
    };
    const data = await api
      .post("site/comment/new", params)
      .catch(error => error.response);

    if (data.errors) return form.setError(data.errors);

    form.clear({ name: "", email: "", content: "" });

    alert("Seu comentario sera analisado por um adminstrador.");
  };

  return (
    <Row>
      <Col>
        <hr />
        <PageTitle>Faça um comentário</PageTitle>
        <Form>
          <Form.Group>
            <Form.Label>Nome: </Form.Label>
            <Form.Control
              type="text"
              value={form.getValue("name")}
              onChange={e => onChange("name")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("name")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>E-mail: </Form.Label>
            <Form.Control
              type="email"
              value={form.getValue("email")}
              onChange={e => onChange("email")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("email")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Comentario: </Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              value={form.getValue("content")}
              onChange={e => onChange("content")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("content")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Button
              variant="outline-success"
              disabled={form.hasErrors}
              onClick={handleOnSave}
            >
              Enviar
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};
