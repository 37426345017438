import React from "react";
import { Row as RBRow, Card as RBCard } from "react-bootstrap";
import styled from "styled-components";

import { useLoading } from "hooks";
import api from "api";

const Row = styled(RBRow)`
  margin: 12px 0;
`;

const Card = styled(RBCard)`
  width: 100%;
`;

const CommentContent = styled.div`
  border-bottom: 1px solid #ddd;
  padding-top: 8px;

  &:last-child {
    border-bottom: 0px;
  }
`;

const Comment = ({ label, comment }) => (
  <CommentContent>
    <label>
      <i>{label} diz:</i>
    </label>
    <p>“{comment}”</p>
  </CommentContent>
);

export const ListComments = ({ postId }) => {
  const { data, isLoading } = useLoading(
    () => api.get("/site/comments/approveds?article_id=" + postId),
    false
  );

  if (isLoading || !data) return "carregando";

  const comments = data.success.comments;

  if (comments.length === 0) return false;

  return (
    <Row>
      <Card>
        <Card.Body>
          {comments.map(comment => (
            <Comment label={comment.name} comment={comment.content} />
          ))}
        </Card.Body>
      </Card>
    </Row>
  );
};
