import { useState, useEffect } from "react";
import * as R from "ramda";

export const useForm = (
  { initialValues, validations } = { initialValues: {}, validations: {} }
) => {
  const [values, setValue] = useState({});
  const [errors, setError] = useState({});
  const [touched, setTouched] = useState([]);
  const [triedSave, setTriedSave] = useState(false);
  useEffect(() => {
    const data = initialValues || {};

    const newErrors = { ...validateData(data) };
    setError(newErrors);
    setValue(data);
  }, []);

  const validateData = (data = {}) => {
    return Object.keys(validations).reduce((acc, key) => {
      const error = R.reduceWhile(
        (acc, v) => !acc,
        (acc, v) => (v[0](getValue(key, data), data) ? v[1] : acc),
        null,
        validations[key]
      );

      return { ...acc, ...(error ? { [key]: error } : {}) };
    }, []);
  };

  const onChange = (name, remove = []) => value => {
    const data = { [name]: value };

    setValues(data, remove);
  };

  const setValues = (data, remove = []) => {
    const newValues = { ...R.omit(remove, values), ...data };
    const newErrors = { ...validateData(newValues) };
    const newTouched = [...touched, ...Object.keys(data)];

    setValue(newValues);
    setError(newErrors);
    setTouched([...new Set(newTouched)]);
  };

  const getError = name => {
    return errors[name] && (touched.indexOf(name) !== -1 || triedSave)
      ? errors[name]
      : null;
  };

  const getValue = (name, data = values) => {
    return data[name];
  };

  const clear = fields => {
    setValue(fields);
    setError(fields);
    setTouched([]);
  };

  const trySave = (callback = () => {}) => e => {
    if (!R.isEmpty(errors) && !R.isNil(errors)) {
      setTriedSave(true);
      return false;
    }

    callback(e);
  };

  const form = {
    hasErrors: !R.isEmpty(errors) && !R.isNil(errors),
    getValue,
    setError,
    getError,
    errors,
    values,
    touched,
    trySave,
    setValues,
    clear
  };

  return [form, onChange];
};
