import styled from "styled-components";
import { FaChevronCircleRight, FaChevronCircleDown } from "react-icons/fa";
export const Container = styled.span`
  display: block;
  padding: 10px;
`;

export const IconChevRg = styled(FaChevronCircleRight)`
  margin-right: 4px;
  &:hover {
    color: #fff;
  }
`;

export const IconChevDw = styled(FaChevronCircleDown)`
  margin-right: 4px;
  &:hover {
    color: #fff;
  }
`;

export const Link = styled.a`
  color: #fff;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 14px;
  margin: 0;
  padding: 8px 0;
  color: #f2f2f2;
  cursor: pointer;

  ${({ active }) =>
    !active
      ? ""
      : `
      background: rgba(0,0,0, 0.3);
      color: #fff;
      font-weight: 400;
    `}

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const SubItems = styled.div`
  margin: 0;
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 0 8px;
`;

export const SubItem = styled.span`
  display: block;
  font-size: 12px;
  margin: 0;
  padding: 8px 0;
  color: #f3f3f3;

  cursor: pointer;

  ${({ active }) =>
    !active
      ? ""
      : `
      background: rgba(0,0,0, 0.3);
      color: #fff;
      font-weight: 400;
    `}

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;
