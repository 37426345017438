import React from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "hooks/useForm";
import * as R from "ramda";
import { useLoading } from "hooks";

import api from "api";

const validations = {
  name: [[(data) => !data, "O campo não pode ser vazio"]],
  user: [[(data) => !data, "O campo não pode ser vazio"]],
  password: [[(data) => !data, "O campo não pode ser vazio"]],
  type: [[(data) => !data, "O campo não pode ser vazio"]],
};

export const FormUser = ({ idUser, closeModal }) => {
  const [loading, setLoading] = React.useState(false);
  const [company, setCompany] = React.useState([]);
  const [form, onChange] = useForm({
    validations: idUser ? R.omit(["password"], validations) : validations,
  });

  const handleSaveOrUpdate = async (e) => {
    e.preventDefault();

    setLoading(true);

    const params = {
      name: form.getValue("name"),
      user: form.getValue("user"),
      password: form.getValue("password"),
      company: JSON.stringify(company),
      type: form.getValue("type"),
    };

    const { data } = await api
      .post(!idUser ? "/user/new" : `/user/edit/${idUser}`, params)
      .catch((error) => error.response);

    if (data.errors) return form.setError(data.errors);

    form.clear({ name: "", user: "", password: "", type: "", company: [] });

    setLoading(false);

    closeModal();
  };

  const handleOnSelect = (e) => {
    const selected = parseInt(e.target.value, 10);

    const getValue = form.getValue("company") ? JSON.parse(form.getValue("company")) : [];
    const auxCompany = company && company.length > 0 ? company : getValue;

    const index = auxCompany.indexOf(selected);

    if (index > -1) {
      auxCompany.splice(index, 1);

      setCompany([...auxCompany]);
    } else {
      setCompany([...auxCompany, selected]);
    }
  };

  React.useEffect(() => {
    if (idUser)
      api.get("/user/" + idUser).then((res) => {
        form.setValues(res.data.success.user);
      });
  }, [idUser]);

  const { data, isLoading, fetch } = useLoading(
    () => api.get("/categoryRestricted"),
    false
  );

  const { data: dataCompany, isLoading: isLoadingCompany } = useLoading(
    () => api.get("/company"),
    false
  );

  if (isLoading || isLoadingCompany || !data || !dataCompany)
    return "Carregando...";

  const { categories } = data.success;
  const { companies } = dataCompany.success;

  return (
    <Form>
      <Form.Group>
        <Form.Label>Nome</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nome"
          value={form.getValue("name")}
          onChange={(e) => onChange("name")(e.target.value)}
        />
        <Form.Text className="text-danger">{form.getError("name")}</Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>User</Form.Label>
        <Form.Control
          type="text"
          placeholder="User"
          className="text-lowercase"
          value={form.getValue("user")}
          onChange={(e) => onChange("user")(e.target.value)}
        />
        <Form.Text className="text-danger">{form.getError("user")}</Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>Senha</Form.Label>
        <Form.Control
          type="password"
          placeholder="Senha"
          value={form.getValue("password")}
          onChange={(e) => onChange("password")(e.target.value)}
        />
        <Form.Text className="text-danger">
          {form.getError("password")}
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Label>Nome</Form.Label>
        <Form.Control
          as="select"
          value={form.getValue("type")}
          onChange={(e) => onChange("type")(e.target.value)}
        >
          <option value="" selected disabled>
            Selecione
          </option>
          <option value="USER">USER</option>
          <option value="ADMIN">ADMIN</option>
          <option value="CATEGORIAS">CATEGORIAS</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Form.Control>
        <Form.Text className="text-danger">{form.getError("type")}</Form.Text>
      </Form.Group>

      {form.getValue("type") == "USER" ? (
        <Form.Group>
          <Form.Label>Empresas</Form.Label>
          {companies.map((item) => (
            <Form.Check
              key={item.id}
              type="checkbox"
              value={item.id}
              checked={
                company && company.length > 0
                  ? company.indexOf(item.id) > -1
                  : (form.getValue("company") && form.getValue('company').length > 0 ? JSON.parse(form.getValue("company")).indexOf(item.id) > -1 : false)
              }
              label={item.name}
              onChange={handleOnSelect}
            />
          ))}
        </Form.Group>
      ) : (
        ""
      )}

      <Form.Group>
        <Button
          variant="outline-success"
          disabled={form.hasErrors || loading}
          onClick={handleSaveOrUpdate}
        >
          {loading ? "Salvando" : "Salvar"}
        </Button>
      </Form.Group>
    </Form>
  );
};
