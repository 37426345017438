import React from "react";
import styled from "styled-components";

import { Body } from "components";

const Container = styled.div`
  padding-bottom: 60px;
  padding-top: 30px;
  margin-bottom: -20px;
`;

export const Disabled = () => {
  return (
    <Container>
      <Body>Este serviço está desativado para sua empresa neste momento</Body>
    </Container>
  );
};
