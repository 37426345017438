import React from "react";
import { Container as RBContainer, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { RouterHistory } from "components";

const Span = styled.span`
  font-weight: 400;
  color: #999;
  margin-right: 20px;
  font-size: 15px;
`;

const Strong = styled.b`
  font-weight: 600;
  color: #444;
`;

const Container = styled(RBContainer)`
  margin: 40px 0;
`;

const Info = ({ label, value }) => (
  <Span>
    <Strong>{label} </Strong>
    {value}
  </Span>
);

export const Heading = ({ data }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Info
            label="Você esta em:"
            value={RouterHistory({
              data: { category_id: data.id, category_name: data.name }
            })}
          />
        </Col>
      </Row>
    </Container>
  );
};
