import React from "react";
import styled from "styled-components";
import { Row, Col, Image as RBImage } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";

import Logo from "img/brand.png";
import BgSearch from "img/bg_search.jpg";
import { Body } from "components";
import { useLoading } from "hooks";
import api from "api";

const Container = styled.div`
  padding-bottom: 300px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f2f2f2;
  background-image: url(${BgSearch});
  background-size: cover;
`;

const SubTitle = styled.h3`
  color: #333;
  font-size: 20px;
  margin: 10px 0 20px;
  font-weight: 300;
`;

const SelectLight = styled.span`
  color: #bbb;
  font-size: 14px;
`;

const Image = styled(RBImage)`
  display: block;
  margin: 24px auto 24px 0;
  cursor: pointer;
`;

const SearchCol = styled(Col)`
  padding-top: 200px;
`;

const SearchComponent = ({ history }) => {
  const hashGeral = "075922202003145e6c8eda6c455";
  const [, hash] = window.location.pathname.split("/");
  const { data, isLoading } = useLoading(() =>
    api.get(`/site/articleSearch`, {
      params: { company_hash: hash, company_hash_fixo: hashGeral },
    })
  );

  const goToHome = () => {
    const [, hash] = window.location.pathname.split("/");
    history.push({
      pathname: "/" + hash,
    });
  };

  const handleOnChange = (data) => {
    const article = data[0];

    if (!article) return;

    history.push({
      pathname: `/${hash}/c/${article.category}/a/${article.url}`,
    });
  };

  return (
    <Container>
      <Body>
        <Image onClick={goToHome} src={Logo} />
        <Row className="justify-content-md-center">
          <SearchCol xs={12} md={8}>
            {/* <Title>Como podemos te ajudar?</Title> */}

            <SubTitle>
              {/* "É literalmente verdade que você pode ter melhor sucesso e mais
              rápido ajudando outros a terem sucesso." */}
            </SubTitle>
            {
              <Typeahead
                flip
                id="async-example"
                placeholder="Pesquisar por:"
                isLoading={isLoading}
                options={data ? data.success.articles : []}
                onChange={handleOnChange}
                labelKey="name"
                filterBy={["name", "category_name"]} //, "tags", "error_code"
                renderMenuItemChildren={(option, props) => (
                  <span>
                    <div>
                      {option.name}
                      <SelectLight> {`${option.category_name}`}</SelectLight>
                    </div>

                    {/* {- ${option.tags} tags- ${option.error_code} codigo de erro*/}
                  </span>
                )}
              />
            }
            <Body></Body>
          </SearchCol>
        </Row>
      </Body>
    </Container>
  );
};

export const Search = withRouter(SearchComponent);
