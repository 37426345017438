import React from "react";
import { withRouter } from "react-router-dom";
import { path } from "ramda";

import { useLoading } from "hooks";
import api from "api";

import * as S from "./sidebar.styled";
import { SidebarItem } from "./SidebarItem";
import { Store } from "components";

const SidebarComponent = ({ history, ...props }) => {
  const { setLogout, store } = React.useContext(Store.Context);
  const { data, isLoading } = useLoading(() => api.get("/company"));

  const goTo = (pathname) => () => {
    history.push({
      pathname,
    });
  };

  const handleLogout = () => {
    setLogout();
    history.push("/login");
  };

  return (
    <S.Container {...props}>
      <S.Title>Painel Controle</S.Title>
      {path(["user", "type"], store) === "ADMIN" && (
        <>
          <S.Item onClick={goTo("/admin/users")}>Gerenciar Usuarios</S.Item>
          <S.Item onClick={goTo("/admin/companies")}>Gerenciar Empresas</S.Item>
        </>
      )}

      {isLoading || !data
        ? "carregando"
        : data.success.companies.map((company, key) => (
            <SidebarItem key={key} data={company} />
          ))}

      <S.Exit onClick={handleLogout}>Sair</S.Exit>
    </S.Container>
  );
};

export const Sidebar = withRouter(SidebarComponent);
