import React from "react";
import {
  Container as RBContainer,
  Form as RBForm,
  Col,
  Row,
  Button,
  Image as RBImage,
} from "react-bootstrap";
import styled from "styled-components";

import Logo from "img/brand.png";
import api from "api";
import { useForm } from "hooks";
import { Store } from "components";

const Image = styled(RBImage)`
  display: block;
  margin: 0 auto 20px;
`;

const Container = styled(RBContainer)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Form = styled(RBForm)`
  background: #f2f2f2;
  padding: 20px;
  border: 1px solid #dfdfdf;
`;

const validations = {
  user: [[(data) => !data, "O campo não pode ser vazio"]],
  password: [[(data) => !data, "O campo não pode ser vazio"]],
};

export const Login = ({ history }) => {
  const [form, onChange] = useForm({ validations });
  const { setLogin } = React.useContext(Store.Context);

  const handleOnLogin = async (e) => {
    e.preventDefault();

    const params = {
      user: form.getValue("user"),
      password: form.getValue("password"),
    };

    const { data } = await api
      .post("/login", params)
      .catch((error) => error.response);

    if (data.errors) return form.setError(data.errors);

    setLogin(data.success.user);

    history.push({
      pathname: "/admin",
    });
  };

  return (
    <Container>
      <Content>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <Image src={Logo} />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <Form>
              <Form.Group>
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Seu Usuário"
                  value={form.getValue("user")}
                  onChange={(e) => onChange("user")(e.target.value)}
                />
                <Form.Text className="text-danger">
                  {form.getError("user")}
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Sua Senha"
                  value={form.getValue("password")}
                  onChange={(e) => onChange("password")(e.target.value)}
                />
                <Form.Text className="text-danger">
                  {form.getError("password")}
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Button
                  variant="outline-success"
                  disabled={form.hasErrors}
                  onClick={handleOnLogin}
                  type="submit"
                  block
                >
                  Login
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Content>
    </Container>
  );
};
