import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import { Routes } from "./Routes";

const App = () => {
  return <Routes />;
};

ReactDOM.render(<App />, document.getElementById("root"));
