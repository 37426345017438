import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Form, Button, Image } from 'react-bootstrap';
import Logo from 'img/brand.png';

import { Store } from 'components';
import { useLoading, useForm } from 'hooks';
import api from 'api';
import { path } from 'ramda';

import { Heading } from './Heading';

const Container = styled.div``;

const Item = styled.span`
	cursor: pointer;
	display: block;
	padding: 8px 0;
`;

const validations = {
	user: [[(data) => !data, 'O campo não pode ser vazio']],
	password: [[(data) => !data, 'O campo não pode ser vazio']],
};

export const ListArticlesComponent = ({ history, location, match }) => {
	const { store, setLogin } = React.useContext(Store.Context);
	const [form, onChange] = useForm({ validations });
	const typeUser = path(['user', 'type'], store);

	const { data, isLoading } = useLoading(() => api.get('/site/article?category_id=' + match.params.category), false, [
		location.pathname,
	]);

	const handleOnClick = (value) => {
		history.push({
			pathname: location.pathname + '/a/' + value,
		});
	};

	const handleOnLogin = async (e) => {
		e.preventDefault();

		const params = {
			user: form.getValue('user'),
			password: form.getValue('password'),
		};

		const { data } = await api.post('/login', params).catch((error) => error.response);

		if (data.errors) return form.setError(data.errors);

		setLogin(data.success.user);

		window.location.reload();
	};

	if (!data || isLoading) return 'Carregando';

	if (
		data.success.category.restricted === 'inative' ||
		typeUser === 'CATEGORIAS' ||
		typeUser === 'ADMIN' ||
		parseInt(typeUser) === data.success.category.id
	) {
		return (
			<>
				<Heading data={data.success.category} />{' '}
				<Container>
					{' '}
					{data.success.articles.map((article) => {
						const articleForCompanies = article.companies;

						const articleIsSuportTi = article.companies.filter((item) => {
							return item.id == 1;
						});

						const articleOfCompany = article.companies.filter((item) => {
							return item.hash == match.params.company;
						});

						const suporteTiHash = '075922202003145e6c8eda6c455';

						if (
							articleForCompanies &&
							articleForCompanies.length &&
							match.params.company !== suporteTiHash &&
							!articleIsSuportTi.length &&
							!articleOfCompany.length
						) {
							return false;
						}

						return <Item onClick={() => handleOnClick(article.url)}> {article.name} </Item>;
					})}{' '}
				</Container>{' '}
			</>
		);
	} else {
		return (
			<>
				<Heading data={data.success.category} />{' '}
				<Container>
					<Image src={Logo} style={{ marginBottom: '15px' }} />
					<Form
						style={{
							background: '#f2f2f2',
							padding: '20px',
							border: '1px solid #dfdfdf',
							width: '100%',
							maxWidth: '300px',
						}}
					>
						<Form.Group>
							<Form.Label>Usuario</Form.Label>
							<Form.Control
								type="text"
								placeholder="Seu Usuário"
								value={form.getValue('user')}
								onChange={(e) => onChange('user')(e.target.value)}
							/>
							<Form.Text className="text-danger">{form.getError('user')}</Form.Text>
						</Form.Group>

						<Form.Group>
							<Form.Label>Senha</Form.Label>
							<Form.Control
								type="password"
								placeholder="Sua Senha"
								value={form.getValue('password')}
								onChange={(e) => onChange('password')(e.target.value)}
							/>
							<Form.Text className="text-danger">{form.getError('password')}</Form.Text>
						</Form.Group>

						<Form.Group>
							<Button
								variant="outline-success"
								disabled={form.hasErrors}
								onClick={handleOnLogin}
								type="submit"
								block
							>
								Login
							</Button>
						</Form.Group>
					</Form>
				</Container>
			</>
		);
	}
};

export const ListArticles = withRouter(ListArticlesComponent);
