import React from "react";
import styled from "styled-components";
import { Card as RBCard, Col } from "react-bootstrap";

const CardBody = styled(RBCard.Body)`
  text-align: center;
  cursor: pointer;
`;

const TitleCB = styled.h2`
  margin-bottom: 20px;
  margin: auto;
  margin-top: -24px;
  background: #ffffff;
  padding: 5px 20px;
  text-align: center;
  min-height: 85px;
  font-size: 28px;
`;

const Card = styled(RBCard)`
  border-radius: 0;
  margin: 20px 0 40px;
`;

const DescriptionCB = styled.p`
  min-height: 75px;
`;

const QtsArticles = styled.p``;

export const Bloco = ({ Title, Description, QTArtigos, ...props }) => {
  return (
    <Col {...props}>
      <Card>
        <TitleCB>{Title}</TitleCB>
        <CardBody>
          <DescriptionCB>{Description}</DescriptionCB>
          {/* <QtsArticles>
            {QTArtigos} {QTArtigos === 1 ? "Artigo" : " Artigos"}
          </QtsArticles> */}
        </CardBody>
      </Card>
    </Col>
  );
};

Bloco.defaultProps = {
  md: 4,
  xs: 12,
};
