import React from "react";
import Cookie from "universal-cookie";

import { setToken } from "api";

const StoreContext = React.createContext({});

const cookie = new Cookie();

const Provider = ({ children }) => {
  const [store, setStore] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const dataCookie = cookie.get("session");
    if (!store.token && dataCookie) {
      cookie.set("session", dataCookie, { maxAge: 3600, path: '/' });

      setToken(dataCookie.user.session);
      setStore(prev => ({ ...prev, user: dataCookie.user }));
    }

    setLoading(false);
  }, []);

  const setLogin = user => {
    cookie.set("session", { user }, { maxAge: 3600, path: '/' });

    setToken(user.session);
    setStore(prev => ({ ...prev, user }));
  };

  const setLogout = user => {
    cookie.remove("session");
    setToken();
    setStore({});
  };

  const value = {
    store,
    setLogin,
    setLogout
  };

  if (loading) return "carregando";

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const Store = {
  Context: StoreContext,
  Provider,
  Consumer: StoreContext.Consumer
};
