import React from "react";

import styled from "styled-components";

import { Header, Footer } from "../";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
`;

export const Template = ({ children }) => {
  return (
    <Container>
      <Header />
      {children}
      <Footer />
    </Container>
  );
};
