import React from "react";
import { FaBookOpen } from "react-icons/fa";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { routeGoBack } from "utils/routes";

const Icon = styled.span `
  color: #6fba3b;
  width: 40px;
  height: 40px;
  margin: auto;
  background: #ffffff;
  padding: 4px 0;
  margin-top: -5px;
`;

const Container = styled.div `
  padding: 8px 0;
`;

const Item = styled.div `
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : "normal")};
`;

const SubCategories = styled.div `
  padding: 8px 0 8px 16px;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const SubCategory = styled.div `
  cursor: pointer;
  padding: 8px ${({ level }) => 8 * (level - 1)}px;
  font-style: italic;
  font-weight: ${({ active }) => (active ? 500 : "normal")};
`;

const ItemCategoryComponent = ({ category, history, match }) => {
    const [showSub, setShowSub] = React.useState(false);

    const route_category_id = React.useMemo(
        () => parseInt(match.params.category), [match.params.category]
    );

    React.useEffect(() => {
        if (category.sub_categories.length > 0) {
            const hasInSub = category.sub_categories.find(
                (item) => item.id === route_category_id
            );

            setShowSub(route_category_id === category.id || hasInSub);
        }
    }, [route_category_id]);

    const handleSelectCategory = (value) => () => {
        history.push({
            pathname: routeGoBack() + "/" + value,
        });
    };

    return ( <
        Container >
        <
        Item onClick = { handleSelectCategory(category.id) }
        active = { route_category_id === category.id } >
        <
        Icon as = { FaBookOpen }
        /> {category.name} <
        /Item>

        <
        SubCategories show = { showSub } > {
            category.sub_categories.map((subCategory, i) => ( <
                SubCategory key = { i }
                level = { subCategory.level }
                onClick = { handleSelectCategory(subCategory.id) }
                active = { route_category_id === subCategory.id } >
                { subCategory.name } <
                /SubCategory>
            ))
        } <
        /SubCategories> <
        /Container>
    );
};

export const ItemCategory = withRouter(ItemCategoryComponent);