import styled from "styled-components";
import { Col } from "react-bootstrap";

export const Container = styled(Col)`
  background: #272727;
  color: #f2f2f2;
  font-weight: 300;
  height: 100vh;
  overflow: scroll;

  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.span`
  display: block;
  padding: 20px 10px;
  font-weight: 400;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
`;

export const Item = styled.span`
  display: block;
  padding: 10px;
  cursor: pointer;

  ${({ active }) =>
    !active
      ? ""
      : `
      background: rgba(0,0,0, 0.3);
      color: #fff;
      font-weight: 400;
    `}

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const Exit = styled.span`
  margin-top: auto;
  display: block;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;
