import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { useForm } from "hooks";
import api from "api";

const validations = {
  name: [[(data) => !data, "O campo não pode ser vazio"]],
};
export const NewCompany = ({ fetch }) => {
  const [form, onChange] = useForm({ validations });

  const handleOnSave = async (e) => {
    e.preventDefault();

    const params = {
      name: form.getValue("name"),
    };

    const { data } = await api
      .post("/company/new", params)
      .catch((error) => error.response);

    if (data.errors) return form.setError(data.errors);

    form.clear({ name: "" });
    window.location.reload();
  };

  return (
    <Row>
      <Col md="4">
        <Form>
          <Form.Group>
            <Form.Label> Empresa </Form.Label>{" "}
            <Form.Control
              type="text"
              placeholder="Nome Empresa"
              value={form.getValue("name")}
              onChange={(e) => onChange("name")(e.target.value)}
            />{" "}
            <Form.Text className="text-danger">
              {" "}
              {form.getError("name")}{" "}
            </Form.Text>{" "}
          </Form.Group>{" "}
          <Button
            variant="outline-success"
            disabled={form.hasErrors}
            onClick={handleOnSave}
          >
            Criar{" "}
          </Button>{" "}
        </Form>{" "}
      </Col>{" "}
    </Row>
  );
};
