import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const TitlePage = styled.h1`
  margin: 20px 0;
  font-size: 26px;
`;

export const PageTitle = ({ children, ...props }) => {
  return (
    <Row {...props}>
      <Col md={12} xs={12}>
        <TitlePage>{children}</TitlePage>
      </Col>
    </Row>
  );
};
