import React from "react";
import { Form, Button, Card } from "react-bootstrap";
import styled from "styled-components";

import { useLoading } from "hooks/useLoading";
import { useForm } from "hooks/useForm";
import api from "api";

const SpaceTop = styled.span`
  margin-top: 16px;
  display: block;
`;

const validations = {
  name: [[data => !data, "O campo não pode ser vazio"]]
};
export const FormUpdateCat = ({ categories, idCategory, onClose }) => {
  const [loader, setLoader] = React.useState(false);
  const { isLoading, fetch } = useLoading(
    () => api.get("/category/" + idCategory),
    true
  );

  const [form, onChange] = useForm({ validations });

  React.useEffect(() => {
    if (idCategory)
      fetch().then(data => {
        form.setValues({
          ...data.success.category
        });
      });
  }, []);

  const handleUpdate = async e => {
    e.preventDefault();

    setLoader(true);

    const params = {
      name: form.getValue("name"),
      description: form.getValue("description"),
      category_id: form.getValue("category_id")
    };

    const data = await api
      .post("/category/edit/" + idCategory, params)
      .catch(error => error.response);

    setLoader(false);

    if (data.errors) return form.setError(data.errors);

    onClose();
  };

  return (
    <Card>
      <Card.Body>
        {!isLoading && (
          <Form>
            <Form.Group>
              <Form.Label>Nome Categoria</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome Categoria"
                value={form.getValue("name")}
                onChange={e => onChange("name")(e.target.value)}
              />
              <Form.Text className="text-danger">
                {form.getError("name")}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Descrição da Categoria</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descrição da Categoria"
                value={form.getValue("description")}
                onChange={e => onChange("description")(e.target.value)}
              />
              <Form.Text className="text-danger">
                {form.getError("description")}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Categoria Pai</Form.Label>
              <Form.Control
                as="select"
                value={form.getValue("category_id")}
                onChange={e =>
                  onChange("category_id")(parseInt(e.target.value, 10))
                }
              >
                <option value="">Nenhum</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.level
                      ? `\u00A0\u00A0\u00A0`.repeat(category.level - 1)
                      : ""}
                    {category.name}
                  </option>
                ))}
              </Form.Control>

              <SpaceTop />
              <Button
                variant="outline-success"
                disabled={loader || form.hasErrors}
                onClick={handleUpdate}
              >
                {loader ? "Atualizando..." : "Atualizar"}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};
