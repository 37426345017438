import { FaHeart } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { Image as RBImage } from "react-bootstrap";

export const pulse = keyframes`
  0%{
    padding: 4px;
  }
  100%{
    padding: 0px;
  }
`;

export const Container = styled.div`
  margin-top: auto;
  text-align: center;
`;

export const FaixaLogo = styled.div`
  background: #292929;
  margin-top: 20px;
`;

export const Card = styled.div`
  background: #6fba3b;
  display: inline-block;
  padding: 0;
  margin: -20px auto 0;
`;

export const ImageLogo = styled(RBImage)`
  display: block;
  padding: 20px 8px;
  width: 180px;
`;

export const FaixaInfo = styled.div`
  margin-top: 20px;
  background: #292929;
  padding: 0 0 40px;
`;

export const Image = styled(RBImage)`
  margin: 40px auto 40px;
  display: block;
`;

export const AInfo = styled.a`
  font-size: 16px;
  color: #f2f2f2;
  font-weight: 300;
  &:hover {
    color: #f2f2f2;
  }
`;

export const FaixaFooter = styled.div`
  background: #171717;
  padding: 20px 0;
  &:hover {
    .heart-footer {
      animation: ${pulse} 0.3s infinite;
    }
  }
`;

export const Text = styled.p`
  color: #f2f2f2;
  margin: 0;
  font-weight: 300;
`;

export const IconHeart = styled(FaHeart)`
  color: #f00;
  margin-bottom: 4px;
  transition: 0.3s;
  width: 22px;
  height: 22px;
  animation: ${pulse} 0.8s infinite;
`;

export const Link = styled.a`
  color: #f2f2f2;
  &:hover {
    color: #f2f2f2;
  }
`;
