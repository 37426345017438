import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { PageTitle } from "components";
import { FormCategories } from "./components/FormCategories";
import { ListCategories } from "./components/ListCategories";

import { useLoading } from "hooks";
import api from "api";

const Spaco = styled.div`
  margin-top: 24px;
`;

export const Categories = ({ location, match }) => {
  const { data, isLoading, fetch } = useLoading(
    () => api.get("/category?company_id=" + match.params.company),
    false,
    [location.pathname]
  );

  if (isLoading || !data) return "carregando"; // deu erro quando, colocado na tabela

  return (
    <>
      <Row>
        <Col>
          <PageTitle>Adminstrador de Categorias</PageTitle>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <FormCategories
            fetchCategory={fetch}
            categories={data.success.categories}
          />
        </Col>
      </Row>
      <Spaco />

      <ListCategories
        fetchCategory={fetch}
        categories={data.success.categories}
      />
    </>
  );
};
