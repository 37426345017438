import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const Link = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child):after {
    content: " / ";
  }
`;

export const RouterHistory = withRouter(({ data, history, match }) => {
  const navigate = pathname => () => {
    history.push({ pathname });
  };

  const routes = [];
  const pathname = [];

  pathname.push("/" + match.params.company);
  routes.push(<Link onClick={navigate(pathname.join(""))}>home</Link>);

  if (data.category_id) {
    pathname.push("/c/" + data.category_id);
    routes.push(
      <Link onClick={navigate(pathname.join(""))}>{data.category_name}</Link>
    );
  }

  if (data.article_id) {
    pathname.push("/a/" + data.article_id);
    routes.push(<Link onClick={navigate(pathname.join(""))}>{data.name}</Link>);
  }

  return routes;
});
