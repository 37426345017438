import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const Container = styled(Row)`
  margin: 20px auto;
  display: block;

  & img {
    max-width: 100%;
    margin: 10px auto;
    display: block;
  }

  & iframe {
    width: 100%;
    height: 450px;
  }
`;

const formatYoutube = data =>
  data.replace(/<oembed\surl=\"[^m]*m\/watch[^>]*><\/oembed>/gi, video => {
    return video
      .replace("watch", "embed")
      .replace("url", "src")
      .replace("?v=", "/")
      .replace(/oembed/g, "iframe");
  });

export const Content = ({ children }) => {
  return (
    <Container>
      <Col dangerouslySetInnerHTML={{ __html: formatYoutube(children) }} />
    </Container>
  );
};
