import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";

import { PageTitle } from "components";
import Logo from "img/brand.png";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 70%;
`;

export const Dashboard = () => {
  return (
    <>
      <PageTitle>Base de Conhecimento</PageTitle>
      <hr />
      <Container>
        <Image src={Logo} />
      </Container>
    </>
  );
};
