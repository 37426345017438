import React from "react";
import { Row, Col, Form, Button as RBButton, Card } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import { path } from "ramda";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { PageTitle, Store } from "components";
import { useForm, useLoading } from "hooks";
import api, { baseURL } from "api";
import { routeGoBack } from "utils/routes";
import styled from "styled-components";

import { AdminCategories } from "./components/AdminCategories";

const InfoTags = styled(Card)`
  margin-top: 24px;
`;

const InfoCodes = styled(Card)`
  margin-top: 24px;
`;

const Footer = styled.div``;

const ButtonSaveArticle = styled(RBButton)`
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 30px;
`;

const validations = {
  name: [[(data) => !data, "O campo não pode ser vazio"]],
  visible: [[(data) => !data, "O campo não pode ser vazio"]],
  categories: [
    [(data) => !data, "O campo não pode ser vazio"],
    [(data) => !data.length, "O campo não pode ser vazio"],
  ]
};

export const NewArticle = ({ history, match }) => {
  const { store } = React.useContext(Store.Context);
  const [form, onChange] = useForm({
    initialValues: { visible: "", categories: [], companies: [] },
    validations,
  });

  const { fetch } = useLoading(
    () => api.get("/article/" + match.params.article),
    true
  );

  React.useEffect(() => {
    if (match.params.article)
      fetch().then((data) => {
        const company = data.success.article.companies ? data.success.article.companies : [] 
        form.setValues({
          ...data.success.article,
          tags: data.success.article.tags.join(","),
          categories: data.success.article.categories.map((data) =>
            parseInt(data)
          ),
          companies: company.map((data) =>
            parseInt(data)
          ),
        });
      });
  }, []);

  const handleOnSave = async (e) => {
    e.preventDefault();
    const params = {
      id: match.params.article,
      company_id: match.params.company,
      name: form.getValue("name"),
      visible: form.getValue("visible"),
      content: form.getValue("content"),
      categories: form.getValue("categories"),
      companies: form.getValue("companies"),
      tags: (form.getValue("tags") || "").split(","),
      error_code: form.getValue("error_code"),
    };

    const uri = match.params.article ? "/article/edit" : "/article/new";

    const { data } = await api
      .post(uri, params)
      .catch((error) => error.response);

    if (data.errors) return form.setError(data.errors);

    history.push({ pathname: routeGoBack(match.params.article ? 2 : 1) });
  };

  return (
    <>
      <PageTitle>Editor de Artigo</PageTitle>
      <hr />

      <Row>
        <Col md="8">
          <Form>
            <Form.Group as={Row}>
              <Col md="6">
                <Form.Label>Nome Artigo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Passo a passo para melhorar o mundo."
                  value={form.getValue("name")}
                  onChange={(e) => onChange("name")(e.target.value)}
                />
                <Form.Text className="text-danger">
                  {form.getError("name")}
                </Form.Text>
              </Col>

              <Col md="6">
                <Form.Label>Visibilidade</Form.Label>
                {path(["user", "type"], store) === "ADMIN" ? (
                  <Form.Control
                    as="select"
                    value={form.getValue("visible")}
                    onChange={(e) => onChange("visible")(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecione
                    </option>
                    <option value="draft">Rascunho</option>
                    <option value="public">Publico</option>
                    <option value="disabled">Desabilitado</option>
                    <option value="pre-public">Pré-Publico</option>
                  </Form.Control>
                ) : (
                  <Form.Control
                    as="select"
                    value={form.getValue("visible")}
                    onChange={(e) => onChange("visible")(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecione
                    </option>
                    <option value="draft">Rascunho</option>
                    <option value="pre-public">Publico</option>
                  </Form.Control>
                )}

                <Form.Text className="text-danger">
                  {form.getError("visible")}
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col md="12">
                <Form.Label>Conteúdo</Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={form.getValue("content")}
                  config={{
                    ckfinder: {
                      uploadUrl: `${baseURL}/article/uploadImage`,
                    },
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    onChange("content")(data);
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
        </Col>

        <Col md="4">
          <AdminCategories
            onChange={onChange("categories")}
            onChangeCompany={onChange("companies")}
            valueCompany={form.getValue("companies")}
            value={form.getValue("categories")}
            error={form.getError("categories")}
            errorCompany={form.getError("companies")}
          />

          <InfoTags>
            <Card.Header>Tags</Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Informe as tags relacionadas</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: outlook, trocar a senha, etc"
                  value={form.getValue("tags")}
                  onChange={(e) => onChange("tags")(e.target.value)}
                />
              </Form.Group>
              <Form.Text className="text-danger">
                {form.getError("tags")}
              </Form.Text>
            </Card.Body>
          </InfoTags>

          <InfoCodes>
            <Card.Header>Codigo de Erro</Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Informe o codigo de erro</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 5462"
                  value={form.getValue("error_code")}
                  onChange={(e) => onChange("error_code")(e.target.value)}
                />
              </Form.Group>
              <Form.Text className="text-danger">
                {form.getError("error_code")}
              </Form.Text>
            </Card.Body>
          </InfoCodes>
        </Col>
      </Row>

      <Footer>
        <ButtonSaveArticle
          variant="success"
          onClick={form.trySave(handleOnSave)}
        >
          Salvar Artigo
        </ButtonSaveArticle>
      </Footer>
    </>
  );
};
