import React from 'react';
import { Form } from 'react-bootstrap';
import { Store } from 'components';
import { path } from 'ramda';

export const ListCompany = ({ match, companies, value = [], onChange, error }) => {
	const { store } = React.useContext(Store.Context);
	const handleOnSelect = (e) => {
		const selected = parseInt(e.target.value, 10);

		if (value.includes(selected)) {
			return onChange(value.filter((item) => item !== selected));
		}

		onChange([...value, selected]);
	};

	const companyUser = path(['user', 'company'], store);
	const userType = path(['user', 'type'], store);

	return (
		<Form>
			<Form.Group id="formGridCheckbox">
				{' '}
				{companies.map((item) => {
					if (
            userType === 'ADMIN' ||
            (userType === 'USER' && companyUser && JSON.parse(companyUser).indexOf(item.id) > -1)
          ) {
            return <Form.Check
							key={item.id}
							type="checkbox"
							label={item.name}
							value={item.id}
							checked={value.includes(item.id)}
							onChange={handleOnSelect}
						/>
          } 
          
          return false;
				})}{' '}
			</Form.Group>{' '}
			<Form.Text className="text-danger"> {error} </Form.Text>{' '}
		</Form>
	);
};
