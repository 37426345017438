import React from "react";
import { Row, Col } from "react-bootstrap";

import IcoEnv from "img/ico_env.png";
import IcoFone from "img/ico_fone.png";
import IcoMap from "img/ico_map.png";
import Logo from "img/logo_footer.png";

import { Body } from "../";
import * as S from "./footer.styled";

const Info = ({ imgSrc, text, href, ...props }) => {
  return (
    <Col xs={12} md={4} {...props}>
      <S.Image src={imgSrc} />
      <S.AInfo href={href} target="_blank">
        {text}
      </S.AInfo>
    </Col>
  );
};

export const Footer = () => {
  return (
    <S.Container>
      <S.FaixaInfo>
        <Body>
          <S.Card>
            <S.ImageLogo src={Logo} />
          </S.Card>

          <Row>
            <Info
              imgSrc={IcoMap}
              text="Al. Rio Negro, 1477 . Alphaville - SP"
              href="https://www.google.com/maps/search/?api=1&query=SuporTI"
            />

            <Info
              imgSrc={IcoFone}
              text=" (11) 4780 9530"
              href="tel:1147809530"
            />

            <Info
              imgSrc={IcoEnv}
              text="contato@suporti.com"
              href="contato@suporti.com"
            />
          </Row>
        </Body>
      </S.FaixaInfo>

      <S.FaixaFooter>
        <Body>
          <S.Text>
            TODOS OS DIREITOS RESERVADOS - SUPORTI | FEITO COM{" "}
            <S.IconHeart className="heart-footer" /> POR{" "}
            <S.Link href="https://centoevinte.com.br" target="_blank">
              CENTO E VINTE
            </S.Link>
          </S.Text>
        </Body>
      </S.FaixaFooter>
    </S.Container>
  );
};
