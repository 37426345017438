import React from "react";
import styled from "styled-components";

import { Container as RBContainer, Row, Col } from "react-bootstrap";

import { Sidebar } from "./components/Sidebar";

const Container = styled(RBContainer)`
  height: 100vh;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
`;

const Content = styled(Col)`
  max-height: 100vh;
  overflow: scroll;
`;

export const TemplateAdmin = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Sidebar md="2" xs="12" />
        <Content md="10" xs="12">
          {children}
        </Content>
      </Row>
    </Container>
  );
};
