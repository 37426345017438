import React from "react";
import { Row, Col, Button, Table, Modal } from "react-bootstrap";
import styled from "styled-components";

import { PageTitle, Store } from "components";
import { useLoading } from "hooks";
import api from "api";
import { formatDate } from "utils/date";
import { path } from "ramda";

const Th = styled.th`
  font-weight: 400;
`;

const Red = styled.span`
  color: red;
`;

const Space = styled.div`
  margin-top: 30px;
`;

const SpaceLeft = styled.span`
  display: inline-block;
  margin-left: 10px;
`;

const Select = styled.select`
  padding: 6px 20px 9px;
  border-radius: 4px;
  border: 1px solid #28a745;
  color: #28a745;
`;

export const Article = ({ history, location, match }) => {
  const { store } = React.useContext(Store.Context);
  const [showModal, setShowModal] = React.useState(false);
  const [filter, setFilter] = React.useState({});

  const { data, isLoading, fetch } = useLoading(
    () =>
      api.get("/article", {
        params: { company_id: match.params.company, ...filter },
      }),
    false,
    [location.pathname, filter]
  );

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
  } = useLoading(
    () => api.get("/category?company_id=" + match.params.company),
    false,
    [location.pathname]
  );

  const handleNewArticle = () =>
    history.push({ pathname: location.pathname + "/new" });

  const handleEdit = (id) => {
    history.push({ pathname: location.pathname + `/${id}/edit` });
  };

  const handleSetRestricted = async (set, article) => {
    await api.post("/article/setRestricted/" + article, { set });
    fetch();
  };

  const handleDelete = async () => {
    await api.post("/article/delete/" + showModal.article);
    setShowModal(false);

    fetch();
  };

  return (
    <>
      <PageTitle>Administrador De Artigos</PageTitle>

      <hr />

      <Row>
        <Col>
          <Button variant="outline-success" onClick={handleNewArticle}>
            Criar Novo Artigo
          </Button>
          <SpaceLeft />
        </Col>
      </Row>

      <Space />
      <hr />

      <Row>
        <Col>
          {!dataCategories || isLoadingCategories ? (
            "Carregando"
          ) : (
            <>
              Filtre por categoria
              <SpaceLeft />
              <Select
                id="SelectFilterCategory"
                value={filter.category}
                onChange={({ target }) => {
                  setFilter((prev) => ({
                    ...prev,
                    category: target.value,
                  }));
                }}
              >
                <option value="">Todas</option>
                {dataCategories.success.categories.map((category) => (
                  <option value={category.id}>
                    {(category.level
                      ? `-`.repeat(category.level - 1) + " "
                      : "") + category.name}
                  </option>
                ))}
              </Select>
            </>
          )}
        </Col>
        <Col>
          Filtre por Status
          <SpaceLeft />
          <Select
            id="SelectVisible"
            value={filter.visible}
            onChange={({ target }) => {
              setFilter((prev) => ({
                ...prev,
                visible: target.value,
              }));
            }}
          >
            <option value="">Todos</option>
            <option value="pre-public">Pré Publicado</option>
            <option value="public">Publicado</option>
            <option value="draft">Rascunho</option>
            <option value="disabled">Desabilitado</option>
          </Select>
        </Col>
      </Row>

      <Space />
      {isLoading || !data ? (
        "Carregando"
      ) : (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <Th>Artigo</Th>
              <Th>Data Criação</Th>
              <Th>Views</Th>
              <Th>Likes</Th>
              <Th>Deslikes</Th>
              <Th>Status</Th>
              <Th>Ação</Th>
            </tr>
          </thead>

          <tbody>
            {data.success.articles.map((article) => (
              <tr key={article.id}>
                <td>{article.name}</td>
                <td>{formatDate(article.date)}</td>
                <td>{article.views}</td>
                <td>{article.likes}</td>
                <td>{article.unlikes}</td>
                <td>
                  {article.visible === "draft" && <Red>Rascunho</Red>}
                  {article.visible === "public" && "Publicado"}
                  {article.visible === "pre-public" && "Pré Publicado"}
                  {article.visible === "disabled" && "-Desabilitado"}
                </td>
                <td>
                  <Button
                    size="sm"
                    variant="outline-info"
                    href={`${window.location.origin}/${article.hash}/c/${article.category_id}/a/${article.url}`}
                    target="_blank"
                  >
                    Visualizar
                  </Button>
                  <SpaceLeft />
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => handleEdit(article.id)}
                  >
                    Editar
                  </Button>
                  <SpaceLeft />
                  {article.restricted === "inative" ? (
                    <Button
                      variant="outline-warning"
                      size="sm"
                      onClick={() => handleSetRestricted("active", article.id)}
                    >
                      Restringir
                    </Button>
                  ) : (
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() =>
                        handleSetRestricted("inative", article.id)
                      }
                    >
                      Liberar
                    </Button>
                  )}
                  <SpaceLeft />
                  {path(["user", "type"], store) === "USER" &&
                  article.visible !== "public" ? (
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() =>
                        setShowModal({
                          showModal: true,
                          article: article.id,
                        })
                      }
                    >
                      Excluir
                    </Button>
                  ) : (
                    ""
                  )}

                  {path(["user", "type"], store) === "ADMIN" && (
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() =>
                        setShowModal({
                          showModal: true,
                          article: article.id,
                        })
                      }
                    >
                      Excluir ADMIN
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Exclusao de Artigo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja excluir este artigo permanentemente?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowModal(false)}>
            Não
          </Button>
          <Button variant="outline-danger" onClick={handleDelete}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
