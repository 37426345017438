import React from "react";

import { PageTitle } from "components";
import { ListCompanies } from "./components/ListCompanies";
import { NewCompany } from "./components/NewCompany";

import { useLoading } from "hooks";
import api from "api";

export const Companies = () => {
  const { data, isLoading, fetch } = useLoading(() => api.get("/company"));

  return (
    <>
      <PageTitle>Gerenciador de Empresas</PageTitle>
      {isLoading || !data ? (
        "Carregando"
      ) : (
        <>
          <NewCompany fetch={fetch} />
          <hr />
          <ListCompanies companies={data.success.companies} fetch={fetch} />
        </>
      )}
    </>
  );
};
