import React from "react";

import { useLoading } from "hooks";
import api from "api";

export const Validate = ({ match, history }) => {
    const { data } = useLoading(() =>
        api.get("/site/company/" + match.params.company)
    );

    React.useEffect(() => {
        if (data && data.success.company.status !== "active") {
            history.push(`/${match.params.company}/disabled`);
        }
    }, [data]);

    return null;
};