import React from "react";
import { Row, Col } from "react-bootstrap";

import { Body } from "components";
import { ListCategories } from "./components/ListCategories";
import { ListArticles } from "./components/ListArticles";

export const Category = ({ match }) => {
  const category = match.params.category;

  return (
    <Body>
      <Row>
        <Col md="4" xs="12">
          <ListCategories />
        </Col>

        {category && (
          <Col md="8" xs="12">
            <ListArticles />
          </Col>
        )}
      </Row>
    </Body>
  );
};
