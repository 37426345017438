import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import { useLoading } from "hooks";
import api from "api";

import { FormUpdateCat } from "./FormUpdateCat";

const Th = styled.th`
  font-weight: 400;
`;
const SpaceLeft = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

export const ListCategories = ({ categories, fetchCategory }) => {
  const [showModal, setShowModal] = React.useState({ show: false });
  const { fetch: deleteCategory } = useLoading(
    (id) => api.post("/category/delete/" + id),
    true
  );

  const handleOnDelete = async (id) => {
    const data = await deleteCategory(id);

    if (data.error) return alert(data.error.category);
    fetchCategory();
  };

  const handleSetRestricted = async (set, category) => {
    await api.post("/category/setRestricted/" + category, { set });
    fetchCategory();
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <Th>Nome</Th>
            <Th>Descrição</Th>
            <Th>Ação</Th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, key) => (
            <tr>
              <td>
                {category.level
                  ? `\u00A0\u00A0\u00A0`.repeat(category.level - 1)
                  : ""}
                {category.name}
              </td>
              <td> {category.description}</td>
              <td align="center">
                <Button
                  variant="outline-danger"
                  onClick={() => handleOnDelete(category.id)}
                  size="sm"
                >
                  Excluir
                </Button>
                <SpaceLeft />
                <Button
                  variant="outline-primary"
                  onClick={() => setShowModal({ show: true, category: key })}
                  size="sm"
                >
                  Editar
                </Button>
                <SpaceLeft />
                {category.level == 1 ? (
                  category.restricted === "inative" ? (
                    <Button
                      variant="outline-warning"
                      size="sm"
                      onClick={() => handleSetRestricted("active", category.id)}
                    >
                      Restringir
                    </Button>
                  ) : (
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() =>
                        handleSetRestricted("inative", category.id)
                      }
                    >
                      Liberar
                    </Button>
                  )
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal.show && (
        <Modal
          show={showModal.show}
          onHide={() => setShowModal({ show: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{categories[showModal.category].name}</Modal.Title>
          </Modal.Header>
          <FormUpdateCat
            categories={categories}
            idCategory={categories[showModal.category].id}
            onClose={() => {
              setShowModal({ show: false });
              fetchCategory();
            }}
          />
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModal({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
