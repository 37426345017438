import React from 'react';
import { FaBookOpen } from 'react-icons/fa';
import styled from 'styled-components';
import { Card as RBCard, Row, Col, Form, Button, Image } from 'react-bootstrap';
import Logo from 'img/brand.png';

import { routeGoBack } from 'utils/routes';
import { Body, PageTitle, Store } from 'components';
import api from 'api';
import { useLoading, useForm } from 'hooks';
import { path } from 'ramda';

import * as C from './components/Index';

const IconBook = styled(FaBookOpen)`
	color: #6fba3b;
	width: 40px;
	height: 40px;
	margin: auto;
	background: #ffffff;
	padding: 4px 0;
	margin-top: -5px;
`;

const Card = styled(RBCard)`
	padding: 0px 20px;
	margin: 20px 0;
`;

const Space = styled.div`
	margin-bottom: 50px;
`;

const BTToBack = styled(Button)`
	border-radius: 0;
	margin-bottom: 12px;
	padding: 8px 20px;
	width: 100%;
`;

const validations = {
	user: [[(data) => !data, 'O campo não pode ser vazio']],
	password: [[(data) => !data, 'O campo não pode ser vazio']],
};

export const Article = ({ match, location, history }) => {
	const { store, setLogin } = React.useContext(Store.Context);
	const [form, onChange] = useForm({ validations });
	const { data, isLoading } = useLoading(
		() =>
			api.get('/site/article/' + match.params.article, {
				params: {
					category_id: match.params.category,
				},
			}),
		false,
		[location.pathname]
	);

	if (isLoading || !data) {
		return (
			<Body>
				<Card>
					<PageTitle>
						<IconBook /> Carregando...
					</PageTitle>
				</Card>
			</Body>
		);
	}

	const handleOnBack = () => {
		history.push({
			pathname: routeGoBack(2),
		});
	};

	const typeUser = path(['user', 'type'], store);

	const handleOnLogin = async (e) => {
		e.preventDefault();

		const params = {
			user: form.getValue('user'),
			password: form.getValue('password'),
		};

		let { data } = await api.post('/login', params).catch((error) => error.response);

		if (data.errors) return form.setError(data.errors);

		setLogin(data.success.user);

		window.location.reload();
	};


	const companyUser = path(['user', 'company'], store) ? JSON.parse(path(['user', 'company'], store)) : [];
	const companyArticle = data.success.article.companies ? data.success.article.companies.filter((company) => company.hash == match.params.company) : []; 

	let userHasAccess = false;

	if (companyArticle.length && companyUser.length) {
		userHasAccess = companyUser.indexOf(companyArticle[0].id) > -1 ? true : false;
	} else {
		userHasAccess = companyUser.indexOf(parseInt(data.success.article.company_id)) > -1 ? true : false;
	}

	let userIsAdmin = typeUser === 'ADMIN' ? true : false;

	if (
		data.success.article.restricted === 'inative' || userIsAdmin || userHasAccess
	) {
		return (
			<Body>
				<Card>
					<PageTitle>
						{data.success.article.visible !== 'public' && data.success.article.visible !== 'pre-public' ? (
							'Este Artigo Esta Indisponivel'
						) : (
							<>
								<IconBook /> {data.success.article.name}
							</>
						)}
					</PageTitle>
				</Card>

				<C.Heading article={data.success.article} />

				<Row>
					<Col md="4" xs="12">
						<BTToBack variant="success" onClick={handleOnBack}>
							{`<< Voltar`}
						</BTToBack>
						<C.RelactedArticles category={data.success.article.category_id} hash={match.params.company} />
					</Col>
					<Col md="8" xs="12">
						{data.success.article.visible !== 'public' && data.success.article.visible !== 'pre-public' ? (
							'Este Artigo Esta Indisponivel'
						) : (
							<>
								<C.Content children={data.success.article.content} />
								<hr />
								<C.Reactions articleId={data.success.article.id} />
								<hr />
								<C.ListComments postId={data.success.article.id} />
								<C.FormComment postId={data.success.article.id} />
							</>
						)}
					</Col>
				</Row>
				<Space />
			</Body>
		);
	} else {
		return (
			<Body>
				<Card>
					<PageTitle>Artigo restrito</PageTitle>
				</Card>

				<Row>
					<Col md="4" xs="12">
						<BTToBack variant="success" onClick={handleOnBack}>
							{`<< Voltar`}
						</BTToBack>
						<C.RelactedArticles category={data.success.article.category_id} hash={match.params.company} />
					</Col>
					<Col md="8" xs="12">
						<Image src={Logo} style={{ marginBottom: '15px' }} />
						<Form
							style={{
								background: '#f2f2f2',
								padding: '20px',
								border: '1px solid #dfdfdf',
								width: '100%',
								maxWidth: '300px',
							}}
						>
							<Form.Group>
								<Form.Label>Usuario</Form.Label>
								<Form.Control
									type="text"
									placeholder="Seu Usuário"
									value={form.getValue('user')}
									onChange={(e) => onChange('user')(e.target.value)}
								/>
								<Form.Text className="text-danger">{form.getError('user')}</Form.Text>
							</Form.Group>

							<Form.Group>
								<Form.Label>Senha</Form.Label>
								<Form.Control
									type="password"
									placeholder="Sua Senha"
									value={form.getValue('password')}
									onChange={(e) => onChange('password')(e.target.value)}
								/>
								<Form.Text className="text-danger">{form.getError('password')}</Form.Text>
							</Form.Group>

							<Form.Group>
								<Button
									variant="outline-success"
									disabled={form.hasErrors}
									onClick={handleOnLogin}
									type="submit"
									block
								>
									Login
								</Button>
							</Form.Group>
						</Form>
					</Col>
				</Row>
				<Space />
			</Body>
		);
	}
};
