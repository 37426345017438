import React from "react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { Container as RBContainer, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { RouterHistory } from "components";
import { formatDate } from "utils/date";

const Span = styled.span`
  font-weight: 400;
  color: #999;
  margin-right: 20px;
  font-size: 15px;
`;

const Strong = styled.b`
  font-weight: 600;
  color: #444;
`;

const Container = styled(RBContainer)`
  margin: 40px 0;
`;

const Info = ({ label, value }) => (
  <Span>
    <Strong>{label} </Strong>
    {value}
  </Span>
);

export const Heading = ({ article }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Info
            label="Você esta em:"
            value={RouterHistory({
              data: { ...article, article_id: article.url },
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Autor:" value={article.user.name} />
          <Info label="Data:" value={formatDate(article.date)} />
          <Info label={<FaRegThumbsUp />} value={article.likes} />
          <Info label={<FaRegThumbsDown />} value={article.unlikes} />
        </Col>
      </Row>
    </Container>
  );
};
