import React from "react";
import { Card, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { useForm } from "hooks";
import api from "api";

const validations = {
  name: [[data => !data, "O campo não pode ser vazio"]]
};

export const FormCategoriesComponent = ({
  fetchCategory,
  categories,
  match
}) => {
  const [form, onChange] = useForm({ validations });

  const handleOnSave = async e => {
    e.preventDefault();

    const params = {
      company_id: match.params.company,
      category_id: form.getValue("category_id"),
      name: form.getValue("name"),
      description: form.getValue("description")
    };

    const { data } = await api
      .post("category/new", params)
      .catch(error => error.response);

    if (data.errors) return form.setError(data.errors);

    form.setValues({ name: "", category_id: "", description: "" });
    fetchCategory();
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Nome Categoria</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome Categoria"
              value={form.getValue("name")}
              onChange={e => onChange("name")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("name")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Descrição da Categoria</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descrição da Categoria"
              value={form.getValue("description")}
              onChange={e => onChange("description")(e.target.value)}
            />
            <Form.Text className="text-danger">
              {form.getError("description")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Categoria Pai</Form.Label>
            <Form.Control
              as="select"
              value={form.getValue("category_id")}
              onChange={e =>
                onChange("category_id")(parseInt(e.target.value, 10))
              }
            >
              <option key="null" value="">
                Categoria Pai
              </option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.level
                    ? `\u00A0\u00A0\u00A0`.repeat(category.level - 1)
                    : ""}

                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button
            variant="outline-success"
            disabled={form.hasErrors}
            onClick={handleOnSave}
          >
            Salvar
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const FormCategories = withRouter(FormCategoriesComponent);
