import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import api from "api";

import { FormUpdateCompany } from "./FormUpdateCompany";

const Th = styled.th`
  font-weight: 400;
`;

const SpaceLeft = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

export const ListCompanies = ({ companies, fetch }) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleSetBlock = async (set, company) => {
    await api.post("/company/setStatus/" + company, { set });
    fetch();
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <Th>Nome</Th>
            <Th>Link de Acesso Cliente</Th>
            <Th>Ação</Th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, i) => (
            <tr key={i}>
              <td>{company.name}</td>
              <td>
                <a
                  href={`${window.location.origin}/${company.hash}`}
                  target="_blank"
                >
                  {`${window.location.origin}/${company.hash}`}
                </a>
              </td>
              <td>
                <Button
                  variant="outline-info"
                  size="sm"
                  onClick={() =>
                    setShowModal({ showModal: true, company: company.id })
                  }
                >
                  Editar
                </Button>
                <SpaceLeft />
                {company.status === "active" ? (
                  <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={() => handleSetBlock("inative", company.id)}
                  >
                    Bloquear
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleSetBlock("active", company.id)}
                  >
                    Desbloquear
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edição de Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormUpdateCompany
            idCompany={showModal.company}
            onClose={() => {
              setShowModal({ show: false });
              window.location.reload();
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => setShowModal(false)}>
            Cancela
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
