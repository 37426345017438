import React from 'react';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { FormCategories } from './FormCategories';
import { ListCompany } from './ListCompany';
import { ListCategories } from './ListCategories';

import { useLoading } from 'hooks';
import api from 'api';

const AdminCategoriesComponent = ({ match, onChange, onChangeCompany, value, valueCompany, error, errorCompany }) => {
	const { data, isLoading, fetch } = useLoading(() => api.get('/category?company_id=' + match.params.company));

	const { data: dataCompany, isLoading: isLoadingCompany, fetch: fetchCompany } = useLoading(() =>
		api.get('/company')
	);

	return (
		<Card>
			<Card.Header>Mini Gerenciador de Categorias</Card.Header>

			{isLoading || !data || isLoadingCompany || !dataCompany ? (
				'carregando'
			) : (
				<Card.Body>
					<ListCategories
						categories={data.success.categories}
						onChange={onChange}
						value={value}
						error={error}
					/>

					<hr />

					{match.params.company == 1 ? (
						<>
							<ListCompany
								companies={dataCompany.success.companies}
								onChange={onChangeCompany}
								value={valueCompany}
								error={errorCompany}
                match={match}
							/>

							<hr />
						</>
					) : (
						''
					)}

					<FormCategories
						fetchCategory={fetch}
						fetchCompany={fetchCompany}
						categories={data.success.categories}
					/>
				</Card.Body>
			)}
		</Card>
	);
};

export const AdminCategories = withRouter(AdminCategoriesComponent);
