import React from "react";
import { Button, Table } from "react-bootstrap";
import styled from "styled-components";

import { PageTitle } from "components";
import { useLoading } from "hooks";
import api from "api";

const Th = styled.th`
  font-weight: 400;
`;
const Space = styled.div`
  margin-top: 30px;
`;

export const SelectHomeCategories = ({ location, match }) => {
  const { data, isLoading, fetch } = useLoading(
    () => api.get("/category?company_id=" + match.params.company),
    false,
    [location.pathname]
  );

  const handleOnEdit = async (value, idCat) => {
    const {} = await api.post(
      "/category/setIsHome/" + idCat + "?set_show_home=" + value
    );
    fetch();
  };

  return (
    <>
      <PageTitle>Selecionador de Categorias da Pagina Inicial</PageTitle>
      <hr />
      <Space />
      {isLoading || !data ? (
        "carregando"
      ) : (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <Th>Adicionado</Th>
              <Th>Nome</Th>
              <Th>Ação</Th>
            </tr>
          </thead>
          <tbody>
            {data.success.categories.map((category) => (
              <tr>
                <td>{category.is_show_home === 0 ? "Não" : "Sim"}</td>
                <td>
                  {category.level
                    ? `\u00A0\u00A0\u00A0`.repeat(category.level - 1)
                    : ""}
                  {category.name}
                </td>
                <td>
                  {category.is_show_home === 0 ? (
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() => handleOnEdit(1, category.id)}
                    >
                      Adiciona
                    </Button>
                  ) : (
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleOnEdit(0, category.id)}
                    >
                      Remove
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
